



















































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import useDetails from "./useDetails.vue";
import {
  NavBar,
  Field,
  Popup,
  Picker,
  Cell,
  Switch,
  Row,
  Col,
  Image,
  Button,
  Icon,
  Checkbox,
  CheckboxGroup,
} from "vant";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Cell.name]: Cell,
    [Switch.name]: Switch,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Icon.name]: Icon,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    useDetails,
  },
})
export default class AvailableGiftCard extends Mixins(Mixin) {
  @Prop({ default: [] }) public GiftCardList!: Array<member.GiftCardRes>;
  @Prop({ default: [] }) public checkedCardList!: Array<member.GiftCardRes>;
  public bindId = "";
  public giftCardCode = "";
  public activeIndex = 0;
  public checkedList: Array<member.GiftCardRes> = [];
  public checkedListCopy: Array<member.GiftCardRes> = [];
  public checkedListIds: Array<string> = []; //van-check-group 组件的 v-model

  initData(): void {
    this.checkedList = [];
    this.checkedList = JSON.parse(JSON.stringify(this.checkedCardList));

    this.checkedListCopy = [];
    this.checkedListCopy = JSON.parse(JSON.stringify(this.checkedCardList));

    this.checkedListIds = [];
    this.checkedListIds = this.checkedList.map((item) => item.giftCardCode!);
  }
  onClickRight(): void {
    this.$router.push("/bind-card");
  }

  choseCard(): void {
    //点击保存，把的 checkedList 的值 返回去。
    this.$emit("checkGiftCard", this.checkedList);
  }
  onClickLeft(): void {
    //没有点击保存，把一开始留存的 checkedListCopy 的值 返回去。
    this.$emit("checkGiftCard", this.checkedListCopy);
    this.$emit("close");
  }
  checkItem(event: Event, item: member.GiftCardRes): void {
    if (!this.checkedList.find((e) => e.giftCardCode === item.giftCardCode)) {
      this.checkedList.push(item);
    } else {
      this.checkedList.splice(
        this.checkedList.findIndex((e) => item.giftCardCode === e.giftCardCode),
        1
      );
    }
  }
}
